const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

function getParams (url = window.location) {
  let params = {};

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });

  return params;
}

function scrollTo (element) {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop
  if (!element) {
    return;
  }

  let headerOffset = 0;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: element.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

class FilterList {
  constructor(component, options = {}) {
    //const filterControls = $$(".filter-control fieldset", component);
    //const filterButtons = $$(".filter-control > button", component);
    const filterControls = $$("select.filter-control", component);

    const searchControl = $(".filter-search");
    const loadMoreSection = $(".load-more", component.parentNode);
    const emptyMessage = $(".empty-message", component.parentNode);
    const items = $$("div[itemprop='itemListElement']", component.parentNode);
    const filters = $$(".filter-control input[type='radio']", component);
    const displayAmount = 12;
    let filterSelectors = [];
    let queryParams = getParams();

    // filterControls.forEach(fieldset => {
    //   filterSelectors.push(`.filter-control input[name='${fieldset.name}']:checked`);

    //   let items = fieldset.childNodes;
    //   let itemsArr = [];
    //   for (var i in items) {
    //       if (items[i].nodeType == 1) { // get rid of the whitespace text nodes
    //           itemsArr.push(items[i]);
    //       }
    //   }

    //   itemsArr.sort(function(a, b) {
    //     let aSpan = $("span", a);
    //     let bSpan = $("span", b);
    //     let aSpanText = aSpan.innerHTML.toLowerCase();
    //     let bSpanText = bSpan.innerHTML.toLowerCase();

    //     if(bSpanText === 'all') {
    //       return 1;
    //     } else if (aSpanText === 'all') {
    //       return -1;
    //     } else if (aSpanText == bSpanText) {
    //       return 0;
    //     } else if (aSpanText > bSpanText) {
    //       return 1;
    //     } else {
    //       return -1;
    //     }
    //   });

    //   for (let i = 0; i < itemsArr.length; ++i) {
    //     fieldset.appendChild(itemsArr[i]);
    //   }
    // })

    this.displayAmount = displayAmount;
    this.searchControl = searchControl;
    this.filterSelectors = filterSelectors;
    this.loadMoreSection = loadMoreSection;
    this.emptyMessage = emptyMessage;
    this.items = items;
    this.filterControls = filterControls;
    this.revealItems = this.revealItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.initLoadMore = this.initLoadMore.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.component = component;
    let filterItems = this.filterItems;

    filterControls.forEach(select => {
      // Sorting done in xsl, add here if it needs to change.
      select.addEventListener('change', () => {
        this.filterItems();
      })
    })

    //Selects filters when query strings are passed
    let filterQuery = false;
    Object.keys(queryParams).forEach(param => {
      let option = $(`.filter-control[name='${param}'] option[value='${queryParams[param]}']`);
      if (option) {
        $(`.filter-control[name='${param}']`).value = option.value;
      }
    })

    if (filterQuery) {
      scrollTo(component.parentNode);
    }

    if (searchControl) {
      $("input", searchControl).addEventListener('keyup', function(event) {
        if (event.code === 'Enter') {
          event.preventDefault();
          filterItems();
        }
      });
      $("button", searchControl).addEventListener("click", this.filterItems);
    }

    if (loadMoreSection && items.length > displayAmount) {
      loadMoreSection.classList.remove("hidden");
      $("button", loadMoreSection).addEventListener("click", this.loadMore);
    }

    //Catch browsers retained radio button selection on back button hit
    setTimeout(() => {
      filterItems();
    }, 200);
  }

  filterItems() {
    let items = this.items;

    items.forEach(item => {
      item.classList.remove("filtered");
      item.classList.remove("revealed");
    })

    this.filterControls.forEach(select => {
      let name = select.getAttribute("data-keyword");
      let value = select.value;

      if (value !== 'on') {
        items.forEach(item => {
          let content = item.getAttribute(`data-${name}`);
          if (content.indexOf(value) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    })

    if (this.searchControl) {
      let searchInput = $("input", this.searchControl).value.toLowerCase();

      if (searchInput !== '') {
        items.forEach(item => {
          let content = item.textContent.toLowerCase();
          if (content.indexOf(searchInput) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    }

    if ($$("div[itemprop='itemListElement']:not(.filtered)", this.component.parentNode).length === 0) {
      this.emptyMessage.classList.remove("hidden");
    } else {
      this.emptyMessage.classList.add("hidden");
    }

    if (this.loadMoreSection) {
      let remainingItems = $$("div[itemprop='itemListElement']:not(.filtered)", this.component.parentNode);
      remainingItems = Array.prototype.slice.call(remainingItems);
      this.revealItems(remainingItems);
    }
  }

  initLoadMore(targetedItems) {
    this.items.forEach(item => {
      item.classList.remove("hidden");
    })

    if (targetedItems.length > this.displayAmount) {

      targetedItems.forEach((item, index) => {
        if (index >= this.displayAmount) {
          item.classList.add("hidden");
        }
      })

      this.loadMoreSection.classList.remove("hidden");
    } else {
      this.loadMoreSection.classList.add("hidden");
    }
  }

  loadMore() {
    let hiddenNotFiltered = $$("div[itemprop='itemListElement']:not(.revealed):not(.filtered)", this.component.parentNode);
    let items = Array.prototype.slice.call(hiddenNotFiltered);
    this.revealItems(items);
  }

  revealItems(items) {
    let slicedItems = items.slice(0, this.displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length <= this.displayAmount) {
      this.loadMoreSection.classList.add("hidden");
    } else {
      this.loadMoreSection.classList.remove("hidden");
    }
  }
}

const filterForms = document.querySelectorAll(".filters");
for (let i = 0, count = filterForms.length; i < count; i++) {
  new FilterList(filterForms[i], {

  });
}

export default FilterList;
