const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import SnapSlider from '@tannerhodges/snap-slider';

const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
const tabletMediaQuery = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
const mobileMediaQuery = window.matchMedia('(max-width: 575px)');

const galleries = $$(".gallery");

function empty(element) {
  // Just in case it's faster to check for firstChild than lastChild
  // (e.g. if the element list is implemented as a directed linked-list by the UA).
  while (element.firstChild) {
    // In general, it's significantly faster to remove the last element of a collection
    // than it is to remove the first element.
    element.removeChild(element.lastChild);
  }
}

function addNavButtons(gallery) {
  const gallerySlides = $$(".gallery-slide", gallery);
  const galleryNav = $(".slider-nav", gallery);
  const lastButton = $("li:last-child", galleryNav);
  const list = document.createElement("ul");
  let buttonCollection = ``;

  gallerySlides.forEach((slide, index) => {
    buttonCollection = buttonCollection + `<li><button class="dot" type="button"><span class="sr-only">Slide ${index + 1}</span></button></li>`;
  })

  list.innerHTML = buttonCollection;

  galleryNav.insertBefore(list, lastButton);
}

function adjustNav(gallery) {
  const gallerySlides = $$(".gallery-slide", gallery);
  const galleryNav = $(".slider-nav", gallery);
  const buttons = $$("button:not(.end-button)",galleryNav);
  let buttonCount = 0;

  if (desktopMediaQuery.matches) {
    if (gallerySlides.length > 4) {
      buttonCount = gallerySlides.length - 4 + 1;
      galleryNav.classList.remove("hidden");
    } else {
      galleryNav.classList.add("hidden");
    }
  }

  if (tabletMediaQuery.matches) {
    if (gallerySlides.length > 2) {
      buttonCount = gallerySlides.length - 2 + 1;
      galleryNav.classList.remove("hidden");
    } else {
      galleryNav.classList.add("hidden");
    }
  }

  if (mobileMediaQuery.matches) {
    if (gallerySlides.length > 1) {
      buttonCount = gallerySlides.length;
      galleryNav.classList.remove("hidden");
    } else {
      galleryNav.classList.add("hidden");
    }
  }

  buttons.forEach((button, index) => {
    if (index < buttonCount) {
      button.parentNode.classList.remove("!hidden");
    } else {
      button.parentNode.classList.add("!hidden");
    }
  });
}

galleries.forEach(function(gallery) {
  const dialog = $(".gallery-dialog", gallery);
  const dialogContent = $(".dialog-content", dialog);
  const dialogClose = $(".close", dialog);
  const galleryLinks = $$("a.gallery-link", gallery);
  addNavButtons(gallery);
  adjustNav(gallery);

  galleryLinks.forEach(function (link) {
    const href = link.getAttribute("href");

    link.addEventListener("click", function(e) {
      e.preventDefault();

      const image = `<img src="${href}"/>`;

      dialogContent.insertAdjacentHTML("beforeend", image);
      dialog.showModal();
    });
  })

  dialogClose.addEventListener("click", function(e) {
    empty(dialogContent);
    dialog.close();
  })
});

if (galleries.length > 0) {
  let resizeTimer;

  window.addEventListener('resize', function(e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      galleries.forEach((gallery) => {
        adjustNav(gallery);
      })
    }, 250);
  });
}