function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : results[1];
};

document.addEventListener("DOMContentLoaded", function() {
  const q = getUrlParameter("q").replace(" ", "+");
  let start = getUrlParameter("start");
  if (start === "") start = "1";

  const resultsDiv = document.getElementById("searchResults");
  if (!resultsDiv) return;

  fetch("https://icctemzx4l.execute-api.us-west-2.amazonaws.com/default/GoogleCSEGateway?q="+q+"&start="+start+"&s=drilquip")
  .then(function (response) {
    return response.text();
  })
  .then(function(response) {
    console.log(response);
    resultsDiv.innerHTML = response;
  })
  .catch(function (err) {
    // If an error occured, you will catch it here
  });

});