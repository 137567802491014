const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const productForm = $("form.product-form");

if (productForm) {
  const pageHeadline = $("h1");
  const productField = $("input[name='products']", productForm);

  if (productField.value === "") {
    productField.value = pageHeadline.textContent;
  }
}
