const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const desktopMediaQuery = window.matchMedia('(max-width: 1023px)');
const mobileMenu = $("header[role='banner'].platforma-nav-with-utility");

function toggleMobileMenu() {
  const button = $(`.platforma-nav-with-utility [aria-controls="primary-nav"]`);
  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-nav-with-utility");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
}

function openSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

function closeSubnavs() {
  const openMenus = $$(".platforma-nav-with-utility .isParent.open, .platforma-nav-with-utility .isGrandParent.open");
  openMenus.forEach(function(menu) {
    closeSubnav(menu);
  })
}

$(".search-toggle").addEventListener("click", function(event) {
  $("header nav[aria-label='Utility'] form[role='search'] input.search-field").focus();
});

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.platforma-nav-with-utility [aria-controls="primary-nav"]`);
  if (!button) {
    return;
  }

  toggleMobileMenu();
});

document.addEventListener('touchend', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) .isParent > a, .platforma-nav-with-utility:not(.menu-open) .isGrandParent > a");
  if ($(".platforma-nav-with-utility.menu-open")) {
    return;
  }
  
  if (closest) {
    let parent = closest.parentNode;
    if (parent.classList.contains("open")) return;
    closeSubnavs();
    openSubnav(parent);
  } else {
    const inMenu = event.target.closest("li.open");
    if (!inMenu) {
      closeSubnavs();
    }
    return;
  }
  event.preventDefault();
}, false);

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav#primary-nav > ul > .isParent, .platforma-nav-with-utility:not(.menu-open) nav#primary-nav > ul > .isGrandParent");
  if (!closest) return;
  openSubnav(closest);
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav#primary-nav > ul > .isParent, .platforma-nav-with-utility:not(.menu-open) nav#primary-nav > ul > .isGrandParent");
  if (!closest) return;
  closeSubnav(closest);
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) .primary-nav a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    closeSubnav(item);
  })

  if ($navItem.classList.contains("open")) {
    closeSubnav($navItem);
  } else {
    openSubnav($navItem);
  }
}, false);

document.addEventListener('click', function (event) {
  const targets = `
    .platforma-nav-with-utility.menu-open .primary-nav > .isParent > a,
    .platforma-nav-with-utility.menu-open .primary-nav > .isParent > button,
    .platforma-nav-with-utility.menu-open .primary-nav > .isGrandParent > a,
    .platforma-nav-with-utility.menu-open .primary-nav > .isGrandParent > button
  `

  const closest = event.target.closest(targets);
  if (!closest) return;

  const $navItem = closest.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($navItem.classList.contains(`open`)) {
    closeSubnav($navItem);
    $(".platforma-nav-with-utility").classList.remove("submenu-open");
  } else {
    openSubnav($navItem);
    siblings.forEach(sibling => {
      closeSubnav(sibling);
    })
    $(".platforma-nav-with-utility").classList.add("submenu-open");
  }
}, false);
