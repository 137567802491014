import dialogPolyfill from "../../../../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const inSAM = $$("body.samadmin").length > 0;

document.querySelectorAll("dialog.person-dialog").forEach(dialog => {
  dialogPolyfill.registerDialog(dialog);
  // Handles "faux" close button using empty anchor to clear the `target:`
  const closeButton = dialog.querySelector(`a[href="#close"`);
  if (closeButton) {
    closeButton.addEventListener("click", function(event) {
      dialog.close();
    });
  }
});

/**
 * Opens faux modal using CSS `target:` to actual modal.
 */
function openModalByHash() {
  const hash = window.location.hash;
  if (hash) {
    const modal = document.querySelector(`dialog[id="${hash.substring(1)}"]`);
    if (modal && !modal.open) {
      if (inSAM) {
        modal.show();
      } else {
        modal.showModal();
      }
    }
  }
}

window.addEventListener("hashchange", openModalByHash, false);
openModalByHash();
