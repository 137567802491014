const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const menus = $$(".mega-menu");
const subMenus = $$(".sub-menu");

function openPanel(button) {
  const panelID = button.getAttribute("aria-controls");
  const expanded = button.getAttribute("aria-expanded");

  button.setAttribute("aria-expanded", "true");
  $(`#${panelID}`).classList.add("open");
  $(`#${panelID} a`).focus();
}

function closePanels(buttonGroup, panelGroup) {
  buttonGroup.forEach((button) => {
    button.setAttribute("aria-expanded", "false");
  });

  panelGroup.forEach((panel) => {
    panel.classList.remove("open");
  });
}

subMenus.forEach((menu) => {
  const previousContainer = $("button.back", menu);
  previousContainer.addEventListener("click", (e) => {
    $(".platforma-nav-with-utility").classList.remove("submenu-open");
    menu.parentNode.classList.remove("open");
    $("button", menu.parentNode).setAttribute("aria-expanded", "false");
  });
});

menus.forEach((menu) => {
  const megaCardList = $(".mega-card-list", menu);
  const panelContainerOne = $(".depth2", menu);
  const previousContainerOne = $$("button.back", panelContainerOne);

  if (!megaCardList) {
    const buttonGroupOne = $$("div.depth2 button:not(.back)", menu);
    const buttonGroupTwo = $$("div.depth3 button:not(.back)", menu);
    const panelContainerTwo = $(".depth3", menu);
    const panelContainerThree = $(".depth4", menu);
    const panelGroupOne = $$(".nav-panel", panelContainerTwo);
    const panelGroupTwo = $$(".nav-panel", panelContainerThree);
    const previousContainerTwo = $$("button.back", panelContainerTwo);
    const previousContainerThree = $$("button.back", panelContainerThree);

    previousContainerOne.forEach((button) => {
      button.addEventListener("click", (e) => {
        $(".platforma-nav-with-utility").classList.remove("submenu-open");
        menu.parentNode.classList.remove("open");
        $("button",menu.parentNode).setAttribute("aria-expanded", "false");
        closePanels(buttonGroupOne, panelGroupOne);
        closePanels(buttonGroupTwo, panelGroupTwo);
        panelContainerOne.classList.add("focus");
        panelContainerTwo.classList.remove("focus");
        panelContainerThree.classList.remove("focus");
      });
    });

    previousContainerTwo.forEach((button) => {
      button.addEventListener("click", (e) => {
        closePanels(buttonGroupOne, panelGroupOne);
        closePanels(buttonGroupTwo, panelGroupTwo);
        panelContainerOne.classList.add("focus");
        panelContainerTwo.classList.remove("focus");
        panelContainerThree.classList.remove("focus");
      });
    });

    previousContainerThree.forEach((button) => {
      button.addEventListener("click", (e) => {
        closePanels(buttonGroupTwo, panelGroupTwo);
        panelContainerOne.classList.remove("focus");
        panelContainerTwo.classList.add("focus");
        panelContainerThree.classList.remove("focus");
      });
    });

    buttonGroupOne.forEach((button) => {
      button.addEventListener("click", (e) => {
        closePanels(buttonGroupOne, panelGroupOne);
        closePanels(buttonGroupTwo, panelGroupTwo);
        openPanel(button);
        panelContainerOne.classList.remove("focus");
        panelContainerTwo.classList.add("focus");
        panelContainerThree.classList.remove("focus");
      });
    });

    buttonGroupTwo.forEach((button) => {
      button.addEventListener("click", (e) => {
        closePanels(buttonGroupTwo, panelGroupTwo);
        openPanel(button);
        panelContainerOne.classList.remove("focus");
        panelContainerTwo.classList.remove("focus");
        panelContainerThree.classList.add("focus");
      });
    });
  } else {
    previousContainerOne.forEach((button) => {
      button.addEventListener("click", (e) => {
        $(".platforma-nav-with-utility").classList.remove("submenu-open");
        menu.parentNode.classList.remove("open");
        $("button",menu.parentNode).setAttribute("aria-expanded", "false");
        panelContainerOne.classList.add("focus");
      });
    });
  }
});
