const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

if ($("body").classList.contains("samadmin")) {

  const tabChunks = $$(".tabs .tab-panels .SAMChunkWrapper");

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      const el = mutation.target;
      if (el.classList && el.classList.contains("SAMEditing")) {
        const panel = el.closest(".tab-panel");
        const details = el.closest("details");
        details.setAttribute("open", "open");
        panel.style.display = "block";
      }
    });
  });

  tabChunks.forEach((chunk) => {
    observer.observe(chunk, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["class"]
    });
  });
}
