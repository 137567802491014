const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const productGroups = $$(".product-group");

productGroups.forEach(function(group) {
  const loadMoreButton = $(".load-more button", group);
  const products = $$(".products > div.product", group);

  if (products.length > 2) {
    loadMoreButton.parentNode.classList.remove("hidden");
  }

  loadMoreButton.addEventListener("click", function(e){
    e.preventDefault();
    products.forEach(function(product) {
      product.classList.add("reveal");
    })

    loadMoreButton.classList.add("hidden");
  })
});
