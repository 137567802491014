const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const productForm = $("form.general-form");

if (productForm) {
  const recipientField = $("select[name='recipient']");
  const productGroup = $("fieldset.product-field");

  recipientField.addEventListener("change", () => {
    if (recipientField.value === "Products/Services") {
      productGroup.classList.remove("!hidden");
    } else {
      productGroup.classList.add("!hidden");
    }
  })
}
